import React, { useContext, useEffect } from 'react'
import Abouts from '../Abouts'
import AboutHero from '../AboutHero'
import { LoadingContext } from '../Contexts/LoadingContext'
export default function About() {
  const [loading, setLoading] = useContext(LoadingContext)

  useEffect(() => {
    if (loading.enableScreen) {
      setTimeout(() => {
        setLoading({ ...loading, loaded: true })
      }, 4000)
    }
  }, [loading, setLoading])

  return (
    <>
      {loading.enableScreen ? (
        <div className="About">
          <AboutHero />
          <Abouts />
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}
