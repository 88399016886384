import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Navbar from './Components/Navbar'
import Home from './Components/pages/Home'
import './App.css'
import Services from './Components/pages/Services'
import Contact from './Components/pages/Contact'
import About from './Components/pages/About'
import Footer from './Components/Footer'
import ScrollDown from './Components/ScrollBehavior'
import Preloader from './Components/Preloader'
import { LoadingContext } from './Components/Contexts/LoadingContext'

function App() {
  // const [state, setState] = useState(true)
  // const [loaded, setLoaded] = useState(false)
  // const [enableScreen, setEnableScreen] = useState(false)

  const [loading, setLoading] = useState({
    state: true,
    loaded: false,
    enableScreen: false,
  })
  return (
    <>
      <LoadingContext.Provider value={[loading, setLoading]}>
        <ScrollDown />
        <Preloader />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </LoadingContext.Provider>
    </>
  )
}

export default App
