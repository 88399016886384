import React, { useContext } from 'react'
import ServicesCards from '../ServicesCards'
import ServiceHero from '../ServiceHero'
import '../../App.css'
import { LoadingContext } from '../Contexts/LoadingContext'

export default function Services() {
  const [loading, setLoading] = useContext(LoadingContext)
  function ImageHomeLoaded(e) {
    setTimeout(() => {
      setLoading({ ...loading, loaded: true })
    }, 4000)
  }

  window.scrollTo(0, 0) //Scroll to top

  return (
    <>
      {loading.enableScreen ? (
        <div className="Cards_Cards" onLoad={ImageHomeLoaded}>
          <div className="CardsServices">
            <ServiceHero />
            <ServicesCards />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}
