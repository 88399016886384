import React from 'react'
import Hero from './Hero'
import './ServiceHero.css'

const AboutHero = () => {
    return (
        <>
            <Hero 
            title="A propos"
            classN="coverHero"
            paragraphe="Nous concernant"
            />
        </>
    )
}

export default AboutHero
