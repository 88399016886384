import React, { useContext } from 'react'
import HeroSection from '../HeroSection'
import Banner from '../Banner'
import Cards from '../Cards'
import Landing from '../Landing'
import { LoadingContext } from '../Contexts/LoadingContext'

function Home() {
  const [loading, setLoading] = useContext(LoadingContext)

  function ImageHomeLoaded(e) {
    setTimeout(() => {
      setLoading({ ...loading, loaded: true })
    }, 4000)
  }

  return (
    <>
      {loading.enableScreen ? (
        <div onLoad={ImageHomeLoaded}>
          <HeroSection />
          <div className="BannerAndCards">
            <Banner />
            <Landing />
            <Cards />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default Home
