import { useContext, useEffect, useRef } from 'react'
import './style.css'
import gsap from 'gsap'
import { LoadingContext } from '../Contexts/LoadingContext'

function Preloader() {
  // get useContext
  const [loading, setLoading] = useContext(LoadingContext)

  // declare gsap timeline
  let timeline = gsap.timeline({ defaults: { ease: 'SlowMo.easeOut' } })

  // userRef nodes
  const loader = useRef(null)
  const loaderChild = useRef(null)
  const text1 = useRef(null)
  const text2 = useRef(null)
  const text3 = useRef(null)
  const text4 = useRef(null)
  const object1 = useRef(null)

  // useEffect for preloader components
  useEffect(() => {
    // if loading exist
    if (loading.state && loading.loaded === false) {
      setTimeout(async () => {
        await setLoading({ ...loading, state: false })
      }, 500)
    }

    // if  page is loaded
    if (loading.loaded) {
      loadingPage(loaderChild.current)
      offSpinner(object1.current)
      offTitleText(text1.current, text2.current, text3.current, text4.current)
    }

    // display loader
    if (loading.state === false && loading.loaded === false) {
      if (loading.enableScreen === false) {
        setTimeout(async () => {
          // page  loading  after 2 sec
          await setLoading({ enableScreen: true })
        }, 2000)
      }
      gsap.to(loader.current, {
        duration: 0,
        css: { display: 'block' },
      })
      gsap.to(loaderChild.current, {
        duration: 0,
        opacity: 1,
        height: '100%',
        onComplete() {
          // null
        },
      })

      // Enable more animation
      staggerReveal(loaderChild.current)
      titleText(text1.current, text2.current, text3.current, text4.current)
      spinner(object1.current)
    }
  }, [loading, setLoading])

  // function animation  from top to bottom
  const staggerReveal = (node) => {
    gsap.from([node], {
      duration: 1,
      height: 0,
      transformOrigin: 'right top',
      ease: 'power3.inOut',
    })
  }

  // funcion  animation titleText
  const titleText = (node1, node2, node3, node4) => {
    timeline.from([node1, node2, node3, node4], {
      duration: 1,
      y: 150,
      ease: 'elastic.out(1, 0.75)',
      stagger: 0.8,
      delay: 0.5,
    })
  }

  // function for animation spinner
  const spinner = (node) => {
    gsap.to(node, {
      duration: 2,
      opacity: 1,
      delay: 2,
    })
  }

  // animation from bottom to top  if page is loaded
  function loadingPage(node) {
    gsap.to([node], {
      duration: 0.8,
      height: 0,
      ease: 'power3.inOut',
    })
    gsap.to(loader.current, {
      duration: 1,
      css: { display: 'none' },
    })
  }

  const offSpinner = (node) => {
    gsap.to(node, {
      opacity: 0,
      duration: 0.1,
    })
  }

  const offTitleText = (node1, node2, node3, node4) => {
    gsap.to([node1, node2, node3, node4], {
      opacity: 0,
      duration: 0.1,
    })
  }
  return (
    <>
      {!loading.state ? (
        <div ref={loader} className="overlay">
          <div ref={loaderChild} className="wrapper">
            <div className="contents">
              <div className="contents-wrapper">
                <h2>
                  <span>
                    <span ref={text1}>Externalisation opérationnelle</span>
                  </span>
                  <span>
                    <span className="test" ref={text2}>
                      {' '}
                      Préparation de données pour IA
                    </span>
                  </span>
                  <span>
                    <span ref={text3}>Développement web et applications</span>
                  </span>
                  <span>
                    <span ref={text4}> Call Center</span>
                  </span>
                </h2>
                <div className="lds-ellipsis" ref={object1}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default Preloader
