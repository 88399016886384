import React from 'react'
import {Link} from 'react-router-dom';

const FooterContent = (props) => {
    return (
        <> 
            <div className="col-xl-4 ">
                        <div className="title">
                            <h2>
                                {props.title}
                            </h2>
                        </div>
                        <div className="footerContent">
                           <div className="Menu">
                            <p><Link to="/" className="footerLink">{props.Paragraphe1}</Link></p>
                             <p><Link to="/services" className="footerLink">{props.Paragraphe2}</Link></p>
                             <p><Link to="/contact" className="footerLink">{props.Paragraphe3}</Link></p>
                             <p><Link to="/About" className="footerLink"> {props.apropos}</Link></p>
                             <p className="footerLink">{props.ParagrapheAd}</p>
                             <p><a href="mailto:contact@prodevmg.com" className="footerLink">{props.email}</a></p>
                             <p>{props.numero}</p>
                           </div>
                            {/* <p>{props.Paragraphe1}</p>
                            <p>{props.Paragraphe2}</p>
                            <p>{props.Paragraphe3}</p> */}
                            
                            <div className="social">
                                <a href="https://web.facebook.com/profile.php?id=100072667547456&refid=46&__xts__[0]=12.Abp5dWTmDrbDvbVStfJYGqtbn7ocTowQKorfCY_rMK71pxid_stk1WF33wxk6r0NxO5Vv1VnRm1GIdFRPW2zpLqmSsjOrNwdrrRcphQsbnZqCe4ErRZe7Fs1pA4JS-1grnB2vN00s4OlRd3epcB73-yvTXoBBCdZmN_HnsSJ49pXH_rzrUmIxo62HiwCyeOe8SFkGKDDMjxpDhoWunrfu6n1dt6zNcwDJpWMQJ7KofPaPGW_YbuC9wNuBrl1vrMsPh4HvrhDnR4vem3ONCgDGp79ikDHGIYlzXylVFkufF1U5GPFZdkviip6zeApODWnRkvypezcS4dveq29D_9TMWnKe97-M302ExPrJbWoEhCXLX3Uhrh7tbERxvFqPFf_gTGRFvGLd5RsMGVI6aZgk4v2ht6rlHMy6FVzWj86MN78QCK_e8YmH0kNoSiGVoKzhNMJa3oJ23lGkttPo4hMTXDKa3qK2zxXHjCSIbSTCddl0f-iB1UzSe9qOxu96kDLBP0tCHKynzhGT6bMT9FF-4HW5BAIu1dR4DRheyXe0Ulr21r4RQvu0E3NFbi54PeaaBU"><i className={props.facebook}/></a>
                                <i className={props.instagram}/>
                                <i className={props.linkedin}/>
                            </div>
                        </div>
           
                    </div>
                </>
    )
}

export default FooterContent
