import React from 'react'
import * as Scroll from 'react-scroll'

const  Hero = (props) => {

   const scroll =  Scroll.animateScroll
    
        const ScrollDown = () => {
            scroll.scrollTo(1000)
        }
    return (
        
        <div className={props.classN} onClick={ScrollDown}>
            <div className="background"></div>
                <h1 className="Mailaka">{props.title}</h1>
                <p className="paragraphe1">{props.paragraphe}</p>
                <div className="scroll-down">
                    <p>
                        Tap to scroll
                    </p>
                </div>
        </div>
    
    )
}

export default Hero
