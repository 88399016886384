import React from 'react';
import ImgBanner from '../images/banner.png'
import './Banner.css'

function Banner() {
    return (
        <div className="banner"  data-aos="fade-up" data-aos-duration="900">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-12">
                         <img src={ImgBanner} alt="satisfaction client et la fidélisation" width="400px" />
                    </div>
                    <div className="col-xl-7 col-lg-12">
                            <div className="banner_text">
                                 <h2>❝ La <span> satisfaction client est au
cœur</span>de notre culture d’entreprise. Nous adaptons et personnalisons 
                            nos offres selon vos besoins.❞
                                </h2>    
                            </div>
                    </div>
                </div>
            </div>
            
            
            
        </div>
    )
}

export default Banner
