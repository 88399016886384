import React from 'react'

function ServicesCardsItem(props) {

    
    return (
        <div className="ServicesCardsItem"  data-aos={props.aos} data-aos-duration="900" id={props.id} >
            <div className="row">
                <div className="col-xl-6 col-lg-12">
                    <div className={`contains ${props.classIa}`}>
                        <div className="title" >
                            <h1>{props.title}</h1>
                        </div>
                        <div className="paragraph">
                            <p>{props.paragraph}<br />{props.paragraph2}</p>
                            <br />
                            <p> <span style={{fontWeight: "600"}}>{props.title_2}</span> <br />
                           <span className='paragraph-contents'> {props.paragraph3} <br /> <br />{props.paragraph4} <br /> <br /> {props.paragraph5} <br /><br /> {props.paragraph6}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5">
                    <div className="img-col">
                        <img src={props.img} alt={props.alt} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesCardsItem
