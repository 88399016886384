import React, { useContext, useEffect } from 'react'
import FormContact from '../FormContact'
import '../../App.css'
import ContactHero from '../ContactHero'
import { LoadingContext } from '../Contexts/LoadingContext'

export default function Contact() {
  const [loading, setLoading] = useContext(LoadingContext)

  useEffect(() => {
    if (loading.enableScreen) {
      setTimeout(() => {
        setLoading({ ...loading, loaded: true })
      }, 4000)
    }
  }, [loading, setLoading])

  return (
    <>
      {loading.enableScreen ? (
        <div className="contact">
          <ContactHero />
          <FormContact />
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}
