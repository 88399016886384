import React from 'react'
import CardItem from './CardItem'
import Card1 from '../images/card1.avif'
import Card2 from '../images/card2.avif'
import Card4 from '../images/card4.avif'
import Card6 from '../images/card6.avif'
import bpo from '../images/bpoCard.avif'
import service from '../images/service.avif'
import './Cards.css'

const styleDev = {
  fontSize: '23px',
}
const backgroundSav = {
  background: 'linear-gradient(90deg, #730049 10%, #3A0025 90%)',
}
const backgroundDev = {
  background: 'linear-gradient(90deg, #120588 10%, #06A5AB 90%)',
}
const backgroundSupportCommerciaux = {
  background: 'linear-gradient(80deg, #022E60 0%, #000913 60%)',
}
const backgroundTeleprospection = {
  background: 'linear-gradient(90deg, #001C54 0%, #000C11 80%)',
}
const backgroundBpo = {
  background: 'linear-gradient(90deg, #260073 0%, #13003A 80%)',
}
function Cards() {
  return (
    <div className="container">
      <div className="row">
        <CardItem
          src={Card6}
          background={backgroundTeleprospection}
          title="Téléprospection "
          paragraph="Recherche de nouveaux clients, constitution de base de données clients et lancement de nouveaux produits"
          hash="Teleprospection"
          alt="téléprospection"
        />
        <CardItem
          src={Card1}
          background={backgroundSav}
          title="SAV"
          paragraph="Traitement des réclamations clients et informations techniques"
          hash="SAV"
          alt="Services après ventes"
        />
        <CardItem
          src={Card4}
          background={backgroundSupportCommerciaux}
          title="Supports Commerciaux "
          paragraph="Création de supports commerciaux pour la promotion de vos produits ou services"
          hash="Supports-commerciaux"
          alt="Supports-commerciaux"
        />
      </div>
      <div className="row ">
        <CardItem
          src={Card2}
          background={backgroundDev}
          style={styleDev}
          title="Développement web, applications & logiciels "
          hash="Developpement-web"
          alt="Developpement-web"
        />

        <CardItem
          src={bpo}
          background={backgroundBpo}
          title="Préparation de données pour IA"
          title2=""
          paragraph="Réalisation des tâches pour un meilleur gain de temps"
          hash="IA"
          alt="IA"
        />
        <CardItem
          src={service}
          background={backgroundDev}
          title="externalisation opérationnelle"
          paragraph="Réalisation des tâches pour un meilleur gain de temps"
          hash="externalisation-operationnelle"
          alt="BPO & Digital"
        />
      </div>
    </div>
  )
}

export default Cards
