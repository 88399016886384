import React, { useContext } from 'react'
import FooterContent from './FooterContent'
import './Footer.css'
import { LoadingContext } from './Contexts/LoadingContext'

const Footer = () => {
  const [loading] = useContext(LoadingContext)

  return (
    <>
      {loading.enableScreen ? (
        <div className="footer">
          <div className="container">
            <div className="row">
              <FooterContent
                title="Menu"
                Paragraphe1="Accueil"
                Paragraphe2="Services"
                Paragraphe3="Contact"
                apropos="A propos"
              />
              <FooterContent
                title="Adresse"
                ParagrapheAd=" Immeuble Eventic lot 101E Ambohibao Antehiroka -
                    BP 191 Ivato 105 MADAGASCAR
                    "
              />
              <FooterContent
                title="Contact"
                email=" contact@prodevmg.com"
                numero=" +261 34 30 410 45"
                facebook="fab fa-facebook"
                instagram="fab fa-instagram"
                linkedin="fab fa-linkedin"
              />
            </div>
            {/* <div className="row">
              <div className="col-lg-12 flig"> Fenosoa Ramaroson ©</div>
            </div> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default Footer
