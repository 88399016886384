import React, { useState, useRef, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import '../App.css'
import './Navbar.css'
import Logo from '../images/Logo1.png'
import { LoadingContext } from './Contexts/LoadingContext'

function Navbar() {
  // get useContexts

  const [loading] = useContext(LoadingContext)
  // state
  const [click, setclick] = useState(false)
  //   useref
  const inputRef = useRef(null)
  const navMenuRef = useRef(null)

  const handleClick = () => {
    setclick(!click)
  }

  const closeMobileMenu = () => {
    setclick(false)
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  //   if Navbar loaded

  const scrollHandler = () => {
    if (
      window.scrollY > inputRef.current.getBoundingClientRect().top &&
      window.innerWidth >= 1200
    ) {
      document.querySelector('.navbarx').classList.add('fixed')
    } else {
      document.querySelector('.navbarx').classList.remove('fixed')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
  }, [])

  return (
    <>
      {loading.enableScreen ? (
        <nav className="navbarx" ref={inputRef}>
          <div className="navbar-container">
            <NavLink to="/" className="navbar-logo" onClick={scrollToTop}>
              <img src={Logo} alt="Logo" />
            </NavLink>
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'} ref={navMenuRef}>
              <li className="nav-item" onClick={scrollToTop}>
                <NavLink
                  to="/"
                  className="nav-links"
                  activeclassname="active"
                  onClick={closeMobileMenu}
                >
                  Accueil
                </NavLink>
              </li>
              <li className="nav-item" onClick={scrollToTop}>
                <NavLink
                  to="/services"
                  className="nav-links"
                  activeclassname="active"
                  onClick={closeMobileMenu}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item" onClick={scrollToTop}>
                <NavLink
                  to="/contact"
                  className="nav-links"
                  activeclassname="active"
                  onClick={closeMobileMenu}
                >
                  Contact
                </NavLink>
              </li>
              <li className="nav-item" onClick={scrollToTop}>
                <NavLink
                  to="/About"
                  className="nav-links"
                  activeclassname="active"
                  onClick={closeMobileMenu}
                >
                  A propos
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default Navbar
