import React from 'react'
import Hero from './Hero'
import './ServiceHero.css'

function ServiceHero() {
   
    return (
        <>
            <Hero 
            classN="coverHero" 
            paragraphe="Des offres sur mesure" 
            title="Services"
            />
            
        </>
    )
}

export default ServiceHero
