import React from 'react'
import './Abouts.css'

const Abouts = () => {
    return (
        <>
            <div className="Apropos">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h3>
                            Deux partenaires associent leurs compétences et expériences. Ils créent et dirigent PRODEV, basé à Madagascar.                             </h3>
                        </div>
                    </div>    
                <div className="row justify-content-end">
                        <div className="col-xl-11">
                            <p>
                            L'une, ancienne cadre commerciale d'une entreprise de vente de matériels bureautique et informatique, familière aux situations de prospection et de vente, de service après-vente et maîtrisant parfaitement l'organisation relationnelle. 
                            <br/><br/>
                            L'autre, ancien cadre d'opérateur de télécommunications, ayant de l'expérience aux services destinés à de grand nombre de clients aux besoins exigeant de qualité, à l'essor de la numérisation et à la création de valeurs des technologies de l'information et de la communication.
                            </p>
                        </div>
                </div>
                <div className="row ">
                    <div className="col-xl-12">
                        <p>
                        Nous nous engageons à mobiliser en un délai optimal, les moyens humains et organisationnels.                        <br/><br/>
                        Notre mission est d’adapter nos activités et organisations en fonction des objectifs de nos clients, et les accompagner dans leur mutation et innovation.                         </p>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Abouts
