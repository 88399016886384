import React from 'react'
import { Link } from 'react-router-dom'
import './CardItem.css'

function CardItem(props){
    return (
        <div className="col-xl-4 col-lg-5"data-aos="fade-up" data-aos-duration="1200">
            <div className="contains">

            <Link to={{pathname:'./services', hash: props.hash}}>

                <div className="carde" style={props.background}>
                    <div className="imgBox">
                        <img src={props.src} alt={props.alt} />
                    </div>
                    <div className="content" style={props.background} >
                        <h2 style={props.style}>{props.title}<br/><span className='title-bpo'>{props.title2}</span></h2>
                        {/* <p>{props.paragraph}</p> */}
                        <button>En savoir plus</button>
                    </div>
                </div>
            </Link>
        </div>
        </div>
    )
}

export default CardItem