import React  from 'react'
import emailjs from 'emailjs-com'
import './FormContact.css'



function FormContact() {

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_q4tmvik', 'template_s4u4yrp', e.target, 'user_bDZQabFszJvSC2UIa5bYb')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      }

    return (
        <div className="Contact">
            <div className="container">
                <div className="row justify-content-center ">
                    <div className="col-xl-7">
                        <div className="title">
                            <h1>ÉCRIVEZ - <span>NOUS !</span></h1>
                        </div>
                    </div>
                </div>
                <div className="row  justify-content-center">
                    <div className="col-xl-6">
                        <form className="form" onSubmit={sendEmail}>
                            <div className=" ">
                                <div className="  col-xl-6">
                                    <input type="text" placeholder="Nom" name="name" />
                                </div>
                                <div className="row">
                                    <div className="  col-xl-6">
                                        <input type="text" placeholder="Société"  name="societe"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="  col-xl-6">
                                        <input type="text" placeholder="Numero" name="numero" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <input type="email" placeholder="Email" name="email" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <textarea type="text" placeholder="Messages" name="messages"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xl-6">
                                    <input type="submit" className="submit"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            
             </div>
        </div>
    )
}
export default FormContact
// export default FormContact
// import React, {useState} from 'react';
// import emailjs from 'emailjs-com';

// export default function FormContact(){

//     const [form, setForm] = useState({
//         name: "",
//         phone: "",
//         email: "",
//         shippingAddress: "",
//         projectAddress: "",

//         customDesign: "",
//         description: "",
//         image: "",
//         dimensionDetails: "",
//         manufactureDetails: "",

//         designText: "",
//         deadline: "",
//         budget: "",
//         comment: ""
//     })

//     const [count, setCount] = useState(1)

//     const updateForm = (e) => {
//         setForm ({
//             ...form,
//             [e.target.name]: e.target.value,
//         })
//         console.log(e.target)
//         console.log(form)
//     }

//     const sendEmail = (e) => {
//         e.preventDefault();
    
//         emailjs.sendForm(
//             'service_q4tmvik', 
//             'template_s4u4yrp', 
//             e.target,
//             'user_bDZQabFszJvSC2UIa5bYb'
//         )
//           .then((result) => {
//               console.log(
//                   result
//                   );
//           }, (error) => {
//               console.log(error);
//           });
//         e.target.reset();
//     }



//     return (
//         <div className = "form-box">

//             <h5 className = "form-step"> steps: {count} of 4 </h5>

//             <form onSubmit = { sendEmail }  >


//                 <div className = "field1" style={{ visibility: count === 1 ? 'unset' : 'hidden' }}>
//                 <label> customer info </label>
            
//                 <input 
//                     type ="text" 
//                     className = "form-input"
//                     name ="name" 
//                     placeholder="Name"
//                     onChange ={updateForm} 
//                     value = {form.name}
//                 />

                
//                 <input 
//                     type ="tel" 
//                     className = "form-input"
//                     name ="phone" 
//                     placeholder="Phone 000-000-0000"
//                     onChange ={updateForm} 
//                     value = {form.phone}
//                 />

//                 <input 
//                     type ="email" 
//                     className = "form-input"
//                     name ="email" 
//                     placeholder="E-mail"
//                     onChange ={updateForm} 
//                     value = {form.email}
//                 />

//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="shippingAddress" 
//                     placeholder="Shipping Address"
//                     onChange ={updateForm} 
//                     value = {form.shippingAddress}
//                 />

//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="projectAddress" 
//                     placeholder="Physical location of the project"
//                     onChange ={updateForm} 
//                     value = {form.projectAddress}
//                 />
//                 </div>

//                 <div className = "field2" style={{ visibility: count === 2 ? 'unset' : 'hidden' }}>
//                 <label> project description </label>

                
//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="customDesign"
//                     placeholder="Are you interested in a custom design, or do you already have a design to work from?" 
//                     onChange ={updateForm} 
//                     value = {form.customDesign}
//                 />

                
//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="description" 
//                     placeholder="Briefly describe the project and your brand, if applicable"
//                     onChange ={updateForm} 
//                     value = {form.description}
//                 />

//                 Please upload the reference images, including your logo
//                 <input 
//                     type ="file" 
//                     className = "form-input"
//                     name ="image" 
//                     placeholder="image"
                    
//                     onChange ={updateForm} 
//                     value = {form.image}
//                 />
//                 </div>

//                 <div className = "field3" style={{ visibility: count === 3 ? 'unset' : 'hidden' }}>
//                 <label> dimensional details </label>
//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="demensionDetail" 
//                     placeholder="If the design will be painted directly onto a surface, such as a wall or vehicle, please provide dimensions and a description of the surface. Please include maximum working height from ground, and any other details related to access and conditions of the working area."
//                     onChange ={updateForm} 
//                     value = {form.demensionDetail}
//                 />

                
//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="manufactureDetail" 
//                     placeholder="If you are ordering a manufactured sign (wood, metal, canvas etc), please provide dimensions, whether the sign will be single or double-sided, and if you would like hanging hardware to be included."
//                     onChange ={updateForm} 
//                     value = {form.manufactureDetail}
//                 />
//                 </div>

//                 <div className = "field4" style={{ visibility: count === 4 ? 'unset' : 'hidden' }}>
//                 <label> more info </label>

 
//                 <input 
//                     type ="text" 
//                     className = "form-input"
//                     name ="designText" 
//                     placeholder="Text in the design"
//                     onChange ={updateForm} 
//                     value = {form.designText}
//                 />

//                 <input 
//                     type ="date" 
//                     className = "form-input"
//                     name ="deadline" 
//                     placeholder="Deadline"
//                     onChange ={updateForm} 
//                     value = {form.deadline}
//                 />

//                 <input 
//                     type ="text" 
//                     className = "form-input"
//                     name ="budget" 
//                     placeholder="Budget"
//                     onChange ={updateForm} 
//                     value = {form.budget}
//                 />

//                 <textarea 
//                     type ="text" 
//                     className = "form-input"
//                     name ="comment" 
//                     placeholder="Comments"
//                     onChange ={updateForm} 
//                     value = {form.comment}
//                 />
//                 </div>

//                 {/* SUBMIT button */}
//                 {count === 4  ?  (
//                 <button 
//                 type = "submit"
//                 id= "submitBtn"
//                 className = "submitBtn"
//                 > submit</button>
//                 ):null} 

//             </form>
//             {/* end of form */}


//             {/* PREV button */}
//             <button 
//                 className = "prevBtn"
//                 type = "submit" 
//                 onClick = {() => setCount(count -1)} //decrease the page count
//                 disabled = {count <= 1} //don't do anytying when the page is the first page
//             > PREV </button>

//             {/* NEXT button */}
//             <button 
//                 className = "nextBtn"
//                 type = "submit" 
//                 onClick = {() => setCount(count +1)}
//                 disabled = {count >= 4} //at the last page, disable the next button
//             > NEXT </button>

//         </div>
//         // end of form-box
//     )
// }