import React from 'react'
import './HeroSection.css'

function HeroSection({props}) {
 

    return (
        <div className='cover' >
            <div className="container">
                <div className="row">
                    <div className="col-xl-5">
                        <h1 >PRODEV <span>MG</span></h1>
                        <h2>Outsourcing enterprise</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <div className="description">
                             <p>Externalisation opérationnelle, Préparation de données pour IA, Développement web et applications, Call Center.</p>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    )
}

export default HeroSection
