import React from 'react'
import Hero from './Hero'
import './ServiceHero.css'

const ContactHero = (props) => {
    return (
        <>
            <Hero
            classN="coverHero"
            title="Contact"
            paragraphe="Travaillons ensemble"
            />  
        </>
    )
}

export default ContactHero
