import React from 'react'
import { Link } from 'react-router-dom'
import LandingObject from './LandingObject'
import './Landing.css'

const landing = () => {
  return (
    <div className="landing">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="titleConcept">
              <p>Notre concept</p>
            </div>
          </div>
          <div className="col-xl-6 titleConceptContent" data-aos="fade-up" data-aos-duration="1200">
            <p>01. L’humain: </p>
          </div>
          <div className="col-xl-6 paragrapheConcept" data-aos="fade-up" data-aos-duration="1200">
            <p>
            Les humains sont nécessaires pour comprendre l&#39;intention, gérer la subjectivité, identifier les
spécificités et faire face à l&#39;ambiguïté pour garantir la fiabilité.</p>
          </div>
          <div className="col-xl-6 titleConceptContent" data-aos="fade-up" data-aos-duration="1200">
            <p>02. Collaboration et communication :</p>
          </div>
          <div className="col-xl-6 paragrapheConcept" data-aos="fade-up" data-aos-duration="1200">
            <p>
              Nous mettons en œuvre des méthodes d'optimisation de travail pour garantir une
              collaboration fluide et une communication transparente tout le long du projet.
            </p>
            <p>
              Nous assurons notre adaptabilité aux directives et exigences du projet et ne manquons
              pas d’informer nos clients des axes d’amélioration que nous découvrons durant les
              réalisations.
            </p>
          </div>
          <div className="col-xl-6 titleConceptContent" data-aos="fade-up" data-aos-duration="1200">
            <p>03. Qualité</p>
          </div>
          <div className="col-xl-6 paragrapheConcept" data-aos="fade-up" data-aos-duration="1200">
            {' '}
            <p>
              Nous déployons les moyens et ressources nécessaires pour garantir des réalisations de
              qualité et le respect du délai de livraison.
            </p>
            <p>
              Nous formons continuellement nos collaborateurs dans la démarche qualité. Ils sont mis
              à profit pour s’approprier des enjeux et concepts du projet, identifier les
              spécificités et faire face aux ambiguïtés afin de produire des résultats avec
              précision et exactitude.
            </p>
          </div>
          <div className="col-xl-6 titleConceptContent" data-aos="fade-up" data-aos-duration="1200">
            <p>04. Evolutivité:</p>
          </div>
          <div className="col-xl-6 paragrapheConcept" data-aos="fade-up" data-aos-duration="1200">
            <p>
              Nous sommes capables d’effectuer la transition et maintenir la qualité des
              réalisations si les volumes sont augmentés dans le cadre de l’évolution du projet.
            </p>
          </div>
          <div className="col-xl-6 titleConceptContent" data-aos="fade-up" data-aos-duration="1200">
            <p>05. Modèle tarifaire</p>
          </div>
          <div className="col-xl-6 paragrapheConcept" data-aos="fade-up" data-aos-duration="1200">
            <p>
              Notre modèle tarifaire est avantageux et adaptable au volume et peut être ajusté
              lorsqu’il y a un changement dans les directives ou dans la portée des missions. Les
              conditions de paiement sont tout autant souples.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default landing
