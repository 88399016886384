import React, { useEffect } from 'react'
import ServicesCardsItem from './ServicesCardsItem'
import './ServicesCards.css'
import Card2 from '../images/dev.avif'
import telepro from '../images/telepro.avif'
import support from '../images/support.avif'
import bpo from '../images/bpo.avif'
import sav from '../images/sav.avif'
import prestationService from '../images/service.avif'
import { useLocation } from 'react-router-dom'

const styleSav = {
  paddingTop: '8rem',
}
function ServicesCards() {
  const param = useLocation() //get url
  const hash = param.hash // get hash in url #
  useEffect(() => {
    const myElement = document.getElementById(hash)
    if (myElement) {
      // if myElement is mounted
      myElement.scrollIntoView() // scroll into the element tag
    }
  })

  return (
    <div className="ServicesCards">
      <div className="container">
        <ServicesCardsItem
          aos="fade-up"
          title="Téléprospection"
          paragraph="Nous vous accompagnons dans la prospection et les prises de rendez-vous ainsi que la qualification de fichiers  et constitution de database clients."
          img={telepro}
          id="#Teleprospection"
          alt="téléprospection"
        />
        <ServicesCardsItem
          aos="fade-right"
          title="SAV"
          paragraph="Nous assurons la prise en charge des clients et le traitement de leurs demandes."
          img={sav}
          style={styleSav}
          id="#SAV"
          alt="Services après ventes"
        />
        <ServicesCardsItem
          aos="fade-right"
          title="Supports commerciaux "
          paragraph="Nous développons des supports commerciaux pour une meilleure visibilité de l'entreprise et la valorisation de vos produits et services. "
          img={support}
          id="#Supports-commerciaux"
          alt="Supports-commerciaux"
        />
        <ServicesCardsItem
          aos="fade-right"
          title="Développement web, applications & logiciels 
                        "
          paragraph="Nous disposons de ressources compétentes pour la création et la refonte de site web, d’application et logiciel, sur tous types de projets."
          img={Card2}
          id="#Developpement-web"
          alt="Developpement-web"
        />
        <ServicesCardsItem
          aos="fade-right"
          title="Préparation de données pour IA"
          paragraph="Annotations de données et étiquetage de données: "
          paragraph2 = "image, texte, audio, vidéo"
          title_2= "Expériences et références :"
          paragraph3 = "- Segmentation d'un objet d'une image en traçant son contour à l'aide d'un outil polygone puis étiquetage de l'objet segmenté en sélectionnant son type parmi la liste fournie par la base de données. Le traitement est clôturé par la validation des actions."
          paragraph4 = "- Segmentation des divers usagers de la route et panneaux de signalisation à partir d'une image issue d'un caméra embarqué à l'aide d'une bounding box puis annotations des données segmentées."
          paragraph5 = "- Labellisation d'un objet visible sur une image issue de Google Earth sur la plateforme QGIS."
          paragraph6 = "- Annotation linguistique : classification de texte (publication) issu de Twitter. Il s’agit d’identifier la pertinence de l’information, d’identifier les thématiques abordés dans la publication, la localité et les acteurs. Les informations classifiées vont s'intégrer dans une base de données afin de développer un modèle de reconnaissance automatique."
          img={bpo}
          id="#IA"
          alt="IA"
          classIa = "ia"
        />
        <ServicesCardsItem
          aos="fade-right"
          title="externalisation opérationnelle"
          paragraph="Nous avons le savoir-faire en gestion de production et en assurance qualité et les qualités humaines d’analyse, d'observation et de vigilance pour répondre à vos exigences, à des prix avantageux."
          img={prestationService}
          id="#externalisation-operationnelle"
          alt="externalisation opérationnelle"
        />
      </div>
    </div>
  )
}

export default ServicesCards
